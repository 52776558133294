.column-count {
    column-count: 2;
    @include media-breakpoint-down(md) {
        column-count: 1;
    }
}

footer {
    ul {
        li {
            a {
                position: relative;
                padding-left: 15px;
                display: flex;
                &::after {
                    content: " ";
                    position: absolute;
                    top: 14px;
                    left: 0;
                    transform: translate(0, -50%);
                    width: 7px;
                    height: 2px;
                    border-radius: 2px;
                    background: #E5A1A5;
                }
            }
        }
    }
    .copyrights {
        border-top: 1px solid #b5e7ff;
    }
}
@import url('https://fonts.googleapis.com/css2?family=Kalam:wght@700&display=swap');

// @font-face {
//   font-family: 'Satoshi-Variable';
//   src: url('../fonts/Satoshi-Variable.woff2') format('woff2'),
//        url('../fonts/Satoshi-Variable.woff') format('woff'),
//        url('../fonts/Satoshi-Variable.ttf') format('truetype');
//        font-weight: 300 900;
//        font-display: swap;
//        font-style: normal;
// }

// @font-face {
//   font-family: 'Satoshi-Light';
//   src: url('../fonts/Satoshi-Light.woff2') format('woff2'),
//        url('../fonts/Satoshi-Light.woff') format('woff'),
//        url('../fonts/Satoshi-Light.ttf') format('truetype');
//        font-weight: 300;
//        font-display: swap;
//        font-style: normal;
// }

@font-face {
  font-family: 'Satoshi';
  src: url('../fonts/Satoshi-Regular.woff2') format('woff2'),
       url('../fonts/Satoshi-Regular.woff') format('woff'),
       url('../fonts/Satoshi-Regular.ttf') format('truetype');
       font-weight: 400;
       font-display: swap;
       font-style: normal;
}

// @font-face {
//   font-family: 'Satoshi-Medium';
//   src: url('../fonts/Satoshi-Medium.woff2') format('woff2'),
//        url('../fonts/Satoshi-Medium.woff') format('woff'),
//        url('../fonts/Satoshi-Medium.ttf') format('truetype');
//        font-weight: 500;
//        font-display: swap;
//        font-style: normal;
// }

// @font-face {
//   font-family: 'Satoshi-Bold';
//   src: url('../fonts/Satoshi-Bold.woff2') format('woff2'),
//        url('../fonts/Satoshi-Bold.woff') format('woff'),
//        url('../fonts/Satoshi-Bold.ttf') format('truetype');
//        font-weight: 700;
//        font-display: swap;
//        font-style: normal;
// }

// @font-face {
//   font-family: 'Satoshi-Black';
//   src: url('../fonts/Satoshi-Black.woff2') format('woff2'),
//        url('../fonts/Satoshi-Black.woff') format('woff'),
//        url('../fonts/Satoshi-Black.ttf') format('truetype');
//        font-weight: 900;
//        font-display: swap;
//        font-style: normal;
// }


@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(~material-design-icons/iconfont/MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local('Material Icons'),
  local('MaterialIcons-Regular'),
  url(~material-design-icons/iconfont/MaterialIcons-Regular.woff2) format('woff2'),
  url(~material-design-icons/iconfont/MaterialIcons-Regular.woff) format('woff'),
  url(~material-design-icons/iconfont/MaterialIcons-Regular.ttf) format('truetype');
}
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: $font-size-base*$line-height-base;  /* Preferred icon size */
  display: inline-block;
  vertical-align: top;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}
//fix height
.material-icons + span{
  display: inline-block;
}
.small .material-icons,
.btn-sm .material-icons{
  font-size: $small-font-size;
  line-height: $line-height-base;
}
.btn-lg .material-icons{
  font-size: $font-size-lg*$btn-line-height-lg;
}

.pagination .material-icons{
  font-size: $font-size-base*$pagination-line-height;
}
.material-icons.md-18,
{ font-size: 18px; line-height: 1}
.material-icons.md-24,
{ font-size: 24px; line-height: 1}
.material-icons.md-36,
{ font-size: 36px; line-height: 1}
.material-icons.md-48,
{ font-size: 48px; line-height: 1}

#carousel {
    height: 560px;
    background: #F8E0D7;
    .slick-slide {
        img {
            display: block;
            object-fit: cover;
            height: 100%;
            width: 100%;
            @include media-breakpoint-down(sm) {
                object-position: 40% 50%;
            }
        }
        .slider-caption {
            @include media-breakpoint-down(sm) {
                text-align: center;
            }
        }
    }
}

.slick-dots {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translate(-50%);
    display: flex;
    gap: 0.5rem;
    @extend .container;

    .slick-active {
        button {
            background: rgba(#fff, 1);
            position: relative;
            &::after {
                content: " ";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background: #66ccff;
                width: 6px;
                height: 6px;
                border-radius: 3px;
            }
        }
    }

    button {
        border: 0;
        width: 18px;
        height: 18px;
        background: rgba(#fff, 0.5);
        text-indent: 20px;
        overflow: hidden;
        border-radius: 10px;
        &:focus {
            outline: none;
        }
    }
}

.slider-caption {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.slick-slide:not(.slick-current) .img-carousel.lazyload {
    display: none !important;
}

.slick-slide figure {
    display: block !important;
    width: 100%;
    height: 560px;
    margin: 0;
    position: relative;
}

//.carousel {
//  box-shadow: 1px 1px 7px 0 rgba(0, 0, 0, 0.15);
//  margin-bottom: 1.5rem;
//  .direction {
//    z-index: auto;
//  }
//  .carousel-inner {
//    height: 340px;
//    @media (max-width: 767px) {
//      height: auto;
//    }
//  }
//  .carousel-item {
//    height: 100%;
//    img {
//      @media (max-width: 767px) {
//        max-width: 100%;
//        height: auto;
//      }
//      @media (min-width: 768px) {
//      width: 100%;
//      margin-left: 0;
//    }
//    }
//    .caption {
//      position: absolute;
//      @media (min-width: 768px) {
//      bottom: 28px;
//      left: 90px;
//      }
//      @media (max-width: 767px) {
//        bottom: 5px;
//        left: 40px;
//      }
//      color: white;
//      max-width: 340px;
//      .caption-description p {
//        color: white;
//      }
//    }
//    figure {
//      @media (max-width: 767px) {
//        margin: 0;
//      }
//    }
//  }
//  .carousel-control {
//    opacity: 1;
//    .icon-next,
//    .icon-prev {
//      &::before {
//        content: "";
//      }
//      i {
//        font-size: 3.125rem;
//        color: white;
//      }
//      &:hover {
//        i {
//          color: $brand-primary;
//        }
//      }
//    }
//    .icon-prev {
//      left: 1rem;
//    }
//    .icon-next {
//      right: 2rem;
//    }
//    &.left,
//    &.right {
//      background: none;
//    }
//  }
//}